<h2 class="mt-3">{{ "invoices" | i18n }}</h2>
<p *ngIf="!invoices || !invoices.length">{{ "noInvoices" | i18n }}</p>
<table class="table mb-2" *ngIf="invoices && invoices.length">
  <tbody>
    <tr *ngFor="let i of invoices">
      <td>{{ i.date | date : "mediumDate" }}</td>
      <td>
        <a
          href="{{ i.pdfUrl }}"
          target="_blank"
          rel="noopener"
          class="mr-2"
          appA11yTitle="{{ 'downloadInvoice' | i18n }}"
        >
          <i class="bwi bwi-file-pdf" aria-hidden="true"></i
        ></a>
        <a href="{{ i.url }}" target="_blank" rel="noopener" title="{{ 'viewInvoice' | i18n }}">
          {{ "invoiceNumber" | i18n : i.number }}</a
        >
      </td>
      <td>{{ i.amount | currency : "$" }}</td>
      <td>
        <span *ngIf="i.paid">
          <i class="bwi bwi-check text-success" aria-hidden="true"></i>
          {{ "paid" | i18n }}
        </span>
        <span *ngIf="!i.paid">
          <i class="bwi bwi-exclamation-circle text-muted" aria-hidden="true"></i>
          {{ "unpaid" | i18n }}
        </span>
      </td>
    </tr>
  </tbody>
</table>
<h2 class="spaced-header">{{ "transactions" | i18n }}</h2>
<p *ngIf="!transactions || !transactions.length">{{ "noTransactions" | i18n }}</p>
<table class="table mb-2" *ngIf="transactions && transactions.length">
  <tbody>
    <tr *ngFor="let t of transactions">
      <td>{{ t.createdDate | date : "mediumDate" }}</td>
      <td>
        <span *ngIf="t.type === transactionType.Charge || t.type === transactionType.Credit">
          {{ "chargeNoun" | i18n }}
        </span>
        <span *ngIf="t.type === transactionType.Refund">{{ "refundNoun" | i18n }}</span>
      </td>
      <td>
        <i
          class="bwi bwi-fw"
          *ngIf="t.paymentMethodType"
          aria-hidden="true"
          [ngClass]="paymentMethodClasses(t.paymentMethodType)"
        ></i>
        {{ t.details }}
      </td>
      <td
        [ngClass]="{ 'text-strike': t.refunded }"
        title="{{ (t.refunded ? 'refunded' : '') | i18n }}"
      >
        {{ t.amount | currency : "$" }}
      </td>
    </tr>
  </tbody>
</table>
<small class="text-muted">* {{ "chargesStatement" | i18n : "BITWARDEN" }}</small>
