<form #form class="card" (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
  <div class="card-body">
    <button type="button" class="close" appA11yTitle="{{ 'cancel' | i18n }}" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="card-body-header">{{ (add ? "addStorage" : "removeStorage") | i18n }}</h3>
    <div class="row">
      <div class="form-group col-6">
        <label for="storageAdjustment">{{
          (add ? "gbStorageAdd" : "gbStorageRemove") | i18n
        }}</label>
        <input
          id="storageAdjustment"
          class="form-control"
          type="number"
          name="StorageGbAdjustment"
          [(ngModel)]="storageAdjustment"
          min="0"
          max="99"
          step="1"
          required
        />
      </div>
    </div>
    <div *ngIf="add" class="mb-3">
      <strong>{{ "total" | i18n }}:</strong> {{ storageAdjustment || 0 }} GB &times;
      {{ storageGbPrice | currency : "$" }} = {{ adjustedStorageTotal | currency : "$" }} /{{
        interval | i18n
      }}
    </div>
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>{{ "submit" | i18n }}</span>
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
      {{ "cancel" | i18n }}
    </button>
    <small class="d-block text-muted mt-3">
      {{ (add ? "storageAddNote" : "storageRemoveNote") | i18n }}
    </small>
  </div>
</form>
<app-payment [showMethods]="false"></app-payment>
