<!-- Please remove this disable statement when editing this file! -->
<!-- eslint-disable tailwindcss/no-custom-classname -->
<div
  class="tw-mx-auto tw-mt-5 tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-justify-center tw-p-8"
>
  <div>
    <img class="logo logo-themed" alt="Bitwarden" />
    <p class="tw-mx-4 tw-mb-4 tw-mt-3 tw-text-center tw-text-xl">
      {{ "loginOrCreateNewAccount" | i18n }}
    </p>

    <div
      class="tw-mt-3 tw-rounded-md tw-border tw-border-solid tw-border-secondary-300 tw-bg-background tw-p-6"
    >
      <h2 class="tw-mb-6 tw-text-xl tw-font-semibold">{{ "logInInitiated" | i18n }}</h2>

      <div class="tw-text-light">
        <p class="tw-mb-6">{{ "notificationSentDevice" | i18n }}</p>

        <p class="tw-mb-6">
          {{ "fingerprintMatchInfo" | i18n }}
        </p>
      </div>

      <div class="tw-mb-6">
        <h4 class="tw-font-semibold">{{ "fingerprintPhraseHeader" | i18n }}</h4>
        <p>
          <code>{{ fingerprintPhrase }}</code>
        </p>
      </div>

      <div class="tw-my-10" *ngIf="showResendNotification">
        <a [routerLink]="[]" disabled="true" (click)="startPasswordlessLogin()">{{
          "resendNotification" | i18n
        }}</a>
      </div>

      <hr />

      <div class="tw-text-light tw-mt-3">
        {{ "loginWithDeviceEnabledNote" | i18n }}
        <a routerLink="/login">{{ "viewAllLoginOptions" | i18n }}</a>
      </div>
    </div>
  </div>
</div>
