<div class="mt-5 d-flex justify-content-center" *ngIf="loading">
  <div>
    <img class="mb-4 logo logo-themed" alt="Bitwarden" />
    <p class="text-center">
      <i
        class="bwi bwi-spinner bwi-spin bwi-2x text-muted"
        title="{{ 'loading' | i18n }}"
        aria-hidden="true"
      ></i>
      <span class="sr-only">{{ "loading" | i18n }}</span>
    </p>
  </div>
</div>
<div class="container" *ngIf="!loading && !authed">
  <div class="row justify-content-md-center mt-5">
    <div class="col-5">
      <p class="lead text-center mb-4">{{ "joinProvider" | i18n }}</p>
      <div class="card d-block">
        <div class="card-body">
          <p class="text-center">
            {{ providerName }}
            <strong class="d-block mt-2">{{ email }}</strong>
          </p>
          <p>{{ "joinProviderDesc" | i18n }}</p>
          <hr />
          <div class="d-flex">
            <a
              routerLink="/login"
              [queryParams]="{ email: email }"
              class="btn btn-primary btn-block"
            >
              {{ "logIn" | i18n }}
            </a>
            <a
              routerLink="/register"
              [queryParams]="{ email: email }"
              class="btn btn-primary btn-block ml-2 mt-0"
            >
              {{ "createAccount" | i18n }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
