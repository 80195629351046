<bit-simple-dialog>
  <span bitDialogTitle>
    {{ "restoreSelected" | i18n }}
  </span>
  <span bitDialogContent>
    {{ "restoreSelectedItemsDesc" | i18n : cipherIds.length }}
  </span>
  <ng-container bitDialogFooter>
    <button bitButton type="submit" buttonType="primary" [bitAction]="submit">
      {{ "restore" | i18n }}
    </button>
    <button bitButton type="button" (click)="cancel()">{{ "cancel" | i18n }}</button>
  </ng-container>
</bit-simple-dialog>
